import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { Platforms } from '@/enums';
import {
	deviceStorage,
	isInAppBrowserStorage,
	platformStorage,
} from '@/utils/local-storage';
import { Device } from '@capacitor/device';
import { isMobileBrowser } from '@/utils/helpers';
import MobileDetect from 'mobile-detect';

export const useMobileApp = (): {
	initDevice: () => void;
	isMobileApp: boolean;
	platform: ComputedRef<Platforms>;
	isWebMobile: ComputedRef<boolean>;
	isAndroid: ComputedRef<boolean>;
	isIos: ComputedRef<boolean>;
	isWeb: ComputedRef<boolean>;
	isInAppBrowser: ComputedRef<boolean>;
	isTablet: () => boolean | string | null;
} => {
	const isMobileApp = 'cordova' in window;
	const platform = computed(
		() => (platformStorage.value as Platforms) || Platforms.WEB
	);

	const isWeb = computed(() => platform.value === Platforms.WEB);
	const isWebMobile = computed(() => platform.value === Platforms.WEB_MOBILE);
	const isAndroid = computed(() => platform.value === Platforms.ANDROID);
	const isIos = computed(() => platform.value === Platforms.IOS);
	const isInAppBrowser = computed(
		() => isWebMobile.value && isInAppBrowserStorage.value
	);

	const initDevice = () => {
		Device.getInfo().then((data) => {
			deviceStorage.value = JSON.stringify(data);
			if (data.platform === Platforms.WEB && isMobileBrowser()) {
				platformStorage.value = Platforms.WEB_MOBILE;
			} else {
				platformStorage.value = data.platform;
			}
		});
	};

	const isTablet = () => {
		return new MobileDetect(window.navigator.userAgent).tablet();
	};

	return {
		platform,
		isMobileApp,
		initDevice,
		isWeb,
		isWebMobile,
		isAndroid,
		isIos,
		isInAppBrowser,
		isTablet,
	};
};
