import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { ACCOUNT, GAMES, HELP, SURVEYS } from '@/constants/locales';
import type { DesktopNavItem } from '@/types';
import {
	PiGameController,
	PiGameControllerFilled,
	PiHome,
	PiHomeFill,
	PiQuestionCircle,
	PiQuestionCircleFill,
	PiUserCircle,
	PiUserCircleFill,
} from '@primeinsightsgroupllc/prime-icons';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { REWARDS_MODAL } from '@/constants/modals';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useAppStore } from '@/stores/app';
import { USER_ROUTE_NAME } from '@/constants/routes';

export const useNavDesktopItems = (isMobile = false) => {
	const { isTablet, isMobileApp } = useMobileApp();
	const { isOfferwallsAvailable, isOfferwallsPromo } =
		storeToRefs(useUserStore());
	const { isAdjoeEnabled } = storeToRefs(useAppStore());
	const { t } = useI18n();
	const route = useRoute();
	const router = useRouter();

	const activeItemId = ref<string>('');

	const setActiveItemId = (routeName: string | symbol): void => {
		activeItemId.value =
			items.value.find((navItem: DesktopNavItem) => navItem.to === routeName)
				?.id || '';
	};

	const setMobileIcon = (item: DesktopNavItem) => {
		if (activeItemId.value === item.id) return item.iconActive;
		return item.icon;
	};

	const navigationClick = (item: DesktopNavItem) => {
		router.push({ name: item.to });
	};

	const items = computed<Array<DesktopNavItem>>(() => [
		{
			icon: PiHome,
			iconActive: PiHomeFill,
			label: t(SURVEYS),
			to: USER_ROUTE_NAME.SURVEYS,
			active: false,
			disabled: false,
			id: 'surveys',
		},
		{
			icon: PiGameController,
			iconActive: PiGameControllerFilled,
			label: t(GAMES),
			to: USER_ROUTE_NAME.GAMES,
			active: false,
			disabled:
				!(isMobileApp && !isTablet()) ||
				!isOfferwallsAvailable.value ||
				!isAdjoeEnabled.value,
			id: 'games',
			...(isOfferwallsPromo.value && {
				badge: {
					text: isOfferwallsPromo.value,
					textColor: '#ffffff',
					backgroundColor: '#E76F51',
				},
			}),
		},
		{
			icon: PiUserCircle,
			iconActive: PiUserCircleFill,
			label: t(ACCOUNT),
			to: USER_ROUTE_NAME.PROFILE,
			active: false,
			disabled: false,
			id: 'profile',
		},
		{
			icon: PiQuestionCircle,
			iconActive: PiQuestionCircleFill,
			label: t(HELP),
			to: USER_ROUTE_NAME.SUPPORT,
			active: false,
			disabled: false,
			id: 'support',
		},
	]);
	const processItems = computed<DesktopNavItem[]>(() => {
		return items.value
			.map((item: DesktopNavItem) => ({
				...item,
				active: activeItemId.value === item.id,
				icon: isMobile ? setMobileIcon(item) : item.icon,
			}))
			.filter((item: DesktopNavItem) => !item.disabled);
	});

	watch(
		() => route.name,
		async (newVal) => {
			if (!newVal) return;
			await router.isReady();
			setActiveItemId(newVal);
		},
		{ immediate: true }
	);

	return {
		items: processItems,
		navigationClick,
	};
};

export const useMainHeaderActions = () => {
	const { createNewModal } = useModalStorage();

	const openRewardsModal = async () => {
		await createNewModal(REWARDS_MODAL, { position: 'bottom' });
	};

	return {
		openRewardsModal,
	};
};
