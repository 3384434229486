import type { Component } from 'vue';
import {
	DELETE_ACCOUNT_MODAL,
	CHANGE_EMAIL_MODAL,
	CHANGE_PASSWORD_MODAL,
	CHANGE_TIMEZONE_MODAL,
	REWARDS_MODAL,
	REWARD_SENT_MODAL,
	NEW_PASSWORD_SENT,
	CHANGE_LANGUAGE_MODAL,
	SET_USER_PASSWORD_MODAL,
	CONFIRM_CLAIM_MODAL,
	COMPLETED_5_SURVEYS_MODAL,
	LOGOUT_MODAL,
	FIRST_SURVEY_COMPLETED_MODAL,
	HALF_PROGRESS_NOTIFICATION_MODAL,
	DYNAMIC_ANNOUNCEMENT_MODAL,
	CONFIRM_CLAIM_DATA_MODAL,
	HALF_SURVEYS_INFO_MODAL,
	ASK_TRACKING_PERMISSIONS_MODAL,
	REFERRALS_NOTIFICATION_MODAL,
	UPDATE_AVAILABLE_MODAL,
	OFFER_REWARD_DELAY_MODAL,
	OFFER_REWARD_COMPLETE_MODAL,
} from '@/constants/modals';

import DeleteAccountModal from '@/components/modals/DeleteAccountModal.vue';
import ChangeEmailModal from '@/components/modals/ChangeEmailModal.vue';
import ChangePasswordModal from '@/components/modals/ChangePasswordModal.vue';
import ChangeTimezoneModal from '@/components/modals/ChangeTimezoneModal.vue';
import RewardsModal from '@/components/modals/RewardsModal.vue';
import RewardSentModal from '@/components/modals/RewardSentModal.vue';
import NewPasswordSentModal from '@/components/modals/NewPasswordSentModal.vue';
import ChangeLanguageModal from '@/components/modals/ChangeLanguageModal.vue';
import SetUserPasswordModal from '@/components/modals/SetUserPasswordModal.vue';
import ConfirmClaimDataModal from '@/components/modals/ConfirmClaimDataModal.vue';
import ConfirmClaimModal from '@/components/modals/ConfirmClaimModal.vue';
import Completed5SurveysModal from '@/components/modals/Completed5SurveysModal.vue';
import LogoutModal from '@/components/modals/LogoutModal.vue';
import FirstSurveyCompletedModal from '@/components/modals/FirstSurveyCompletedModal.vue';
import HalfProgressNotificationModal from '@/components/modals/notifications/HalfProgressNotificationModal.vue';
import DynamicAnnouncementModal from '@/components/modals/announcements/DynamicAnnouncementModal.vue';
import HalfSurveysInfoModal from '@/components/modals/HalfSurveysInfoModal.vue';
import AskTrackingPermissionsModal from '@/components/modals/AskTrackingPermissionsModal.vue';
import ReferralsNotificationModal from '@/components/modals/notifications/ReferralsNotificationModal.vue';
import UpdateAvailableModal from '@/components/modals/UpdateAvailableModal.vue';
import OfferRewardCompleteModal from "@/components/modals/OfferRewardCompleteModal.vue";
import OfferRewardDelayModal from "@/components/modals/OfferRewardDelayModal.vue";

export const MODAL_MAP: Record<string, Component> = {
	[DELETE_ACCOUNT_MODAL]: DeleteAccountModal,
	[CHANGE_EMAIL_MODAL]: ChangeEmailModal,
	[CHANGE_PASSWORD_MODAL]: ChangePasswordModal,
	[CHANGE_TIMEZONE_MODAL]: ChangeTimezoneModal,
	[REWARDS_MODAL]: RewardsModal,
	[REWARD_SENT_MODAL]: RewardSentModal,
	[NEW_PASSWORD_SENT]: NewPasswordSentModal,
	[CHANGE_LANGUAGE_MODAL]: ChangeLanguageModal,
	[SET_USER_PASSWORD_MODAL]: SetUserPasswordModal,
	[CONFIRM_CLAIM_DATA_MODAL]: ConfirmClaimDataModal,
	[CONFIRM_CLAIM_MODAL]: ConfirmClaimModal,
	[COMPLETED_5_SURVEYS_MODAL]: Completed5SurveysModal,
	[LOGOUT_MODAL]: LogoutModal,
	[FIRST_SURVEY_COMPLETED_MODAL]: FirstSurveyCompletedModal,
	[HALF_PROGRESS_NOTIFICATION_MODAL]: HalfProgressNotificationModal,
	[DYNAMIC_ANNOUNCEMENT_MODAL]: DynamicAnnouncementModal,
	[HALF_SURVEYS_INFO_MODAL]: HalfSurveysInfoModal,
	[ASK_TRACKING_PERMISSIONS_MODAL]: AskTrackingPermissionsModal,
	[REFERRALS_NOTIFICATION_MODAL]: ReferralsNotificationModal,
	[UPDATE_AVAILABLE_MODAL]: UpdateAvailableModal,
	[OFFER_REWARD_DELAY_MODAL]: OfferRewardDelayModal,
	[OFFER_REWARD_COMPLETE_MODAL]: OfferRewardCompleteModal,
};
